@import url("https://fonts.googleapis.com/css2?family=Julius+Sans+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200;300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Homemade+Apple&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pompiere&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap");

@font-face {
  font-family: "Hello Honey";
  font-style: normal;
  font-weight: normal;
  src: url(./honey.otf);
}

@font-face {
  font-family: "Dear Love";
  font-style: normal;
  font-weight: normal;
  src: url(./Dearlove.ttf);
}
* {
  margin: 0;
}

body {
  margin: 0;
  font-family: "Julius Sans One", "Assistant", "Hello Honey" sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
